import React, { Component } from "react";

// import { Link } from 'gatsby';
// import Background_Image from "../repeating_elements/Background-Image.js";
import ProjectItem from "../repeating_elements/Project_Item.js";
import PageBreak from "../repeating_elements/page_break.js";
import LazyLoad from "../repeating_elements/Lazy_Load.js";

import { request_page } from "../../components/data/fetch_data.js";


export default class RelatedStories extends Component {
	constructor(props){
		super(props);
		this.state = {
			data: null
		}
		this.request_page = request_page.bind(this);
	}
	componentDidMount(){
		let request = "posts?include=";
		if(this.props.caseStudies.length === 2){
			request +=  this.props.caseStudies[0].ID + "+" + this.props.caseStudies[1].ID;
		} else if(this.props.caseStudies.length === 1){
			request +=  this.props.caseStudies[0].ID;
		}
		this.request_page("data",request);
	}
	make_sections = (info) => {
		if(info && info.status === "publish"){
			return <div className="project-column grid-m-6 grid-t-8 grid-d-8">
				<LazyLoad>
					<ProjectItem project={info} classes="project-list-1"  />
				</LazyLoad>
			</div>
		} return null;
	}
	render() {
		if(this.state.data){
			return(
				<React.Fragment>
					<PageBreak />
					<div className="related-work grid">
						<h3 className="styling-eyebrow grid-m-6 grid-t-16 grid-d-16">Related work</h3>
						{this.make_sections(this.state.data[0])}
						{this.make_sections(this.state.data[1])}
					</div>
					<PageBreak />
				</React.Fragment>
			);
		}
		return <div className={ "related-work grid" }>
		</div>;
	}
}
