import React, { Component } from 'react';
import { ParallaxProvider } from 'react-scroll-parallax';
import Navigation from "../components/Header/Navigation.js";
import {luma_max, hex_to_luma} from "../components/utilities/hex_to_luma.js";
import PageTransition from 'gatsby-plugin-page-transitions';

import PanoramicHero from "../components/Global/Panoramic_Hero.js";
import Overview from "../components/repeating_elements/Overview.js";
import CaseStudyContent from "../components/Work/Case_Study_Content.js";
import LockedForm from "../components/Work/Locked_Form.js";
import GetInTouchFooter from "../components/Global/Get_In_Touch_Footer.js";
import Footer from "../components/Footer/Footer.js";
import WorkWithUs from "../components/Global/Work_With_Us_Button.js";
import GoBack from "../components/Global/GoBackButton.js";
import GDPR from '../components/Global/GDPR';
// import Loader from '../components/Global/Loader';
import SEO from '../components/seo.js';
import RelatedCaseStudies from "../components/Work/Related_Case_Studies.js";


export default class CaseStudy extends Component {
	constructor(props){
		super(props);
		this.state = {
			isLoaded: this.props.pageContext.isLoaded,
			isLazyLoaded: this.props.pageContext.isLazyLoaded,
			isReady: this.props.pageContext.isReady,
			data: this.props.pageContext.data,
			cookieBanner: this.props.pageContext.cookieBanner,
			headerLinks: this.props.pageContext.header,
			footerLinks: this.props.pageContext.footer,
			posts: this.props.pageContext.posts,
			project_classes: "",
			project_css: ``,
			styles_ready: false
		}
		this.gate_blocked = this.gate_blocked.bind(this);
		this.get_text_color = this.get_text_color.bind(this);
	}
	get_text_color(){
		const parent = this;
		let project_classes = this.state.project_classes;
		const light_nav = `
			.desktop-navigation span,
			.desktop-navigation a,
			.panoramic-hero{
				color: #252525
			}
			.desktop-navigation a::after,
			.drop-arrow .arrow .shaft::after,
			.mobile-header .hamburger-icon-contain .hamburger-icon .bar::before{
				background: #252525
			}
			.desktop-navigation li.center-logo svg,
			.mobile-header .navigation li.center-logo svg,
			.drop-arrow .arrow svg{
				fill: #252525
			}
		`;
		const css = `
			.mobile-header.show-background,
			.desktop-navigation::after{
				background: ${this.state.data.acf.project_color}
			}
			.goback-container .arrow-button {
				background: ${this.state.data.acf.project_color || '#252525'}
			}
		`;
		function apply_light_theme(){
			parent.setState({
				project_classes: project_classes + " dark-text",
				project_css: (css + light_nav),
				styles_ready: true
			});
			document.body.classList.add('dark-text')
		}
		function apply_dark_theme(){
			parent.setState({
				styles_ready: true,
				project_css: css
			});
			document.body.classList.remove('dark-text')
		}
		if(this.state.data){
			if(!this.state.styles_ready){
				if(this.state.data.acf.override_text_color){
					if(this.state.data.acf.project_text_color === "black"){
						apply_light_theme()
					} else{
						apply_dark_theme()
					}
				} else if(hex_to_luma(this.state.data.acf.project_color) > luma_max){
					apply_light_theme()
				} else{
					apply_dark_theme()
				}
			}
		}
	}
	gate_blocked(){
		if(this.state.data.acf.lock_options === "locked" || this.state.data.acf.lock_options === "external_link"){
			return <LockedForm />;
		} else{
			return <React.Fragment>
				<Overview description={this.state.data.acf.overview_description} services={this.state.data.acf.overview_services} awards={this.state.data.acf.overview_awards} site={this.state.data.acf.overview_url} />
				<CaseStudyContent data={this.state.data.acf.case_study_modules} />
				<RelatedCaseStudies caseStudies={this.state.data.acf.footer_feature} />
				<GetInTouchFooter />
				<Footer links={this.state.footerLinks} />
			</React.Fragment>;
		}
	}
	componentDidMount() {
		this.get_text_color()
	}
	render() {
		let page_ready = this.state.isLoaded && this.state.isReady ? " page-ready page-loaded show-text" : " page-loading";
		if(this.state.isLoaded && this.state.data){
			return (
			<ParallaxProvider><PageTransition>
				<GDPR data={this.state.cookieBanner} />

				<SEO
					title={this.state.data.yoast_meta.yoast_wpseo_title}
					siteUrl={this.state.data.yoast_meta.yoast_wpseo_canonical}
					description={this.state.data.yoast_meta.yoast_wpseo_metadesc}
				/>

				<div className="site-contain">
					<Navigation links={this.state.headerLinks} />
					  <div className="page-main">
						<WorkWithUs />
						<GoBack />
						<div className={"page case-study-page " + this.state.project_classes + page_ready} style={{backgroundColor: this.state.data.acf.project_color}}>
							<style>{this.state.project_css}</style>
							<PanoramicHero slides={this.state.data.acf.slides} />
							{ this.gate_blocked() }
                        </div>
                    </div>
				</div>
			</PageTransition></ParallaxProvider>
			)
		} else{
			return (
				<div></div>
			)
		}
	}
}
