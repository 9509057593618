import "isomorphic-fetch"

import React, { Component } from "react";

// import 'whatwg-fetch'

import {ArrowButton, ArrowLink} from "../buttons/Arrow_Link.js";
import DefaultInput from "../Form_Elements/Default_Input.js";

const formId = 969;
const apiDomain = 'https://api.thecharlesnyc.com';

export default class LockedForm extends Component {
	constructor(props){
		super(props);
		this.state = {
      			error_message: false,
     			pathName: null
		};
		this.handleSubmit = this.handleSubmit.bind(this);
		this.show_thank_you = this.show_thank_you.bind(this);
		this.hide_form = this.hide_form.bind(this);
		this.show_form = this.show_form.bind(this);
	}
	componentDidMount() {
		let api_url = apiDomain + '/wp-json/wp/v2/contact_form_7/' + formId;
		fetch(api_url, {
			method: 'GET'
		}).then((response) => response.json())
		.then((responseJson) => this.setState({ formHtml: responseJson, formIsLoaded: true }))
		.catch((error) => {
			console.error(error);
    });
    this.setState({
      pathName: window.location.pathname
    })
	}
	hide_form(){
		let form_container = document.getElementById("JS-form-container");
		// let form_wrapper = document.getElementById("JS-form-wrapper");
		let thanks_wrapper = document.getElementById("JS-thanks-wrapper");

		form_container.classList.add("fade-form");
		form_container.style.height = thanks_wrapper.clientHeight+"px";

		setTimeout(()=>{
			form_container.classList.add("show-thank-you");
		},300)
	}
	show_form(){
		let form_container = document.getElementById("JS-form-container");
		let form_wrapper = document.getElementById("JS-form-wrapper");
		// let thanks_wrapper = document.getElementById("JS-thanks-wrapper");

		form_container.style.height = form_wrapper.clientHeight+"px";

		setTimeout(function(){
			form_container.classList.remove("fade-form");
		},1000)
	}
	show_thank_you(){
		var form_container = document.getElementById("JS-form-container");
		var thank_you_wrapper = document.getElementById("JS-thank-you-wrapper");

		setTimeout(function(){
			form_container.style.height = thank_you_wrapper.clientHeight+"px";
			form_container.classList.add("show-thank-you");
		},1500)
  }
	handleSubmit(event) {
		event.preventDefault();
    let formData = new FormData(event.target);
		let api_url = apiDomain + '/wp-json/contact-form-7/v1/contact-forms/' + formId + '/feedback';

		this.hide_form();

		fetch(api_url, {
			method: 'POST',
			body: formData
		}).then((response) => response.json()).then((responseJson) => {
		})

		fetch(api_url, {
			method: 'POST',
			body: formData
		}).then((response) => response.json()).then((responseJson) => {
			if (responseJson.status === 'validation_failed') {
				var parent = this;

				setTimeout(function(){
					parent.setState({ error_message: responseJson.message })
				},300)
				setTimeout(this.show_form,600)

			} else if (responseJson.status === 'mail_sent') {

				this.setState({ messageSent: true })
				setTimeout(this.show_fshow_thank_youorm,300)

			}

		}).catch((error) => {
			console.error(error);
		});
	}
	render() {
		return <div className="locked-form">
			<div className="form-container">
				<div className="grid">
					<div id="JS-form-container" className="grid-m-6 grid-t-8 push-t-4 grid-d-8 push-d-4 form-content">
						<div id="JS-form-wrapper" className="form-wrapper">
							<div className="form-body">
								<h3>We can't always show you everything, but sometimes all you have to do is ask.</h3>
								<p className="error">{this.state.error_message}</p>
								<form onSubmit={this.handleSubmit}>
									<div className="fields">
										<DefaultInput type="text" name="user-name" additionalClasses="raf-name-field" placeholder="Name*" />
										<DefaultInput type="text" name="user-title" additionalClasses="raf-title-field" placeholder="Title*" />
										<DefaultInput type="text" name="user-company" additionalClasses="raf-company-field" placeholder="Company*" />
										<DefaultInput type="email" name="user-email" additionalClasses="raf-email-field" placeholder="Email*" />
										<DefaultInput type="text" name="user-subject" additionalClasses="raf-subject-field" placeholder="Subject" />
										<DefaultInput type="hidden" name="route-name" value={this.state.pathName}/>
									</div>
									<div className="button-contain">
										<ArrowButton title="Request Access" color="black" />
									</div>
								</form>
							</div>
						</div>
						<div id="JS-thanks-wrapper" className="thank-you-message">
							<div id="JS-thank-you-wrapper" className="thank-you-wrapper">
								<h2>Thank you,<br/> we'll be in touch.</h2>
								<div className="button-contain">
									<ArrowLink title="Back to work" url="/work" color="black" />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>;
	}
}
