import React, { Component } from "react";


import LazyLoad from "../repeating_elements/Lazy_Load.js";
import ImageCarousel from "./Content_Image_Carousel.js";
import PhoneDisplayCarousel from "./Content_Phone_Display_Carousel.js";
import AsymmetricalImages from "./Content_Asymmetrical_Images.js";
import BoxedImages from "./Content_Boxed_Images.js";
import VideoDisplay from "./Content_Video_Display.js";
import StatisticsDisplay from "./Content_Statistics_Display.js";
import CenteredText from "./Content_Centered_Text.js";
import PageBreak from "./Content_Page_Break.js";
import ModuleBreak from "./Content_Module_Break.js";
import MicroCaseStudy from "./Content_Micro_Casestudy.js";

const LazyLoadExt = props => {
	return <LazyLoad className="module">{props.children}</LazyLoad>
}


export default class CaseStudyContent extends Component {
	constructor(props){
		super(props);
		this.count = 0;
		this.render_modules = this.render_modules.bind(this);
		this.render_module = this.render_module.bind(this)
	}
	render_module(module){
		this.count++;
		if(module.acf_fc_layout === "csm_image_carousel"){
			return( <LazyLoadExt><ImageCarousel key={"module-"+this.count} data={module} /></LazyLoadExt> );
		} else if(module.acf_fc_layout === "csm_micro_case_study"){
			return( <LazyLoadExt><MicroCaseStudy key={"module-"+this.count} data={module} /></LazyLoadExt> );
		} else if(module.acf_fc_layout === "csm_phone_display_carousel"){
			return( <LazyLoadExt><PhoneDisplayCarousel key={"module-"+this.count} data={module} /></LazyLoadExt> );
		} else if(module.acf_fc_layout === "csm_asymmetric_images"){
			return( <LazyLoadExt><AsymmetricalImages key={"module-"+this.count} data={module} /></LazyLoadExt> );
		} else if(module.acf_fc_layout === "csm_boxed_images"){
			return( <LazyLoadExt><BoxedImages key={"module-"+this.count} data={module} /></LazyLoadExt> );
		} else if(module.acf_fc_layout === "csm_statistics_display"){
			return( <LazyLoadExt><StatisticsDisplay key={"module-"+this.count} data={module} /></LazyLoadExt> );
		} else if(module.acf_fc_layout === "csm_video_display"){
			return( <LazyLoadExt><VideoDisplay key={"module-"+this.count} data={module} /></LazyLoadExt> );
		} else if(module.acf_fc_layout === "csm_centered_text"){
			return( <LazyLoadExt><CenteredText key={"module-"+this.count} data={module} /></LazyLoadExt> );
		} else if(module.acf_fc_layout === "csm_module_break"){
			return( <ModuleBreak key={"module-"+this.count} /> );
		} else if(module.acf_fc_layout === "csm_page_break"){
			return( <PageBreak key={"module-"+this.count} /> );
		}
	}
	render_modules(){
		if(this.props.data){
			return this.props.data.map( (module) =>{
				return this.render_module(module)
			});
		}
	}
	render(){
		return(
			<div className="case-study-content case-study-image-carousel grid">
				{ this.render_modules() }
			</div>
		);
	}
}
