import React, { Component } from "react";
import Slider from "react-slick";

import SliderButton from "../buttons/Slider_Button.js";
import { ArrowLink } from "../buttons/Arrow_Link.js";
import BackgroundImage from "../repeating_elements/Background-Image.js";


let count = 0;

export default class MicroCaseStudy extends Component {
	constructor(props){
		super(props);
		this.state = {};
		this.render_items = this.render_items.bind(this);
	}
	render_items(data){
		const elements = [];
		data && data.micro_case_study_links && data.micro_case_study_links.forEach((i)=>{
			elements.push(
				<div className="csc-micro-case-study">
					<div className="photo">
						<BackgroundImage url={i.photo} />
					</div>
					<h3 className="styling-body">{i.name}</h3>
					<ArrowLink title="Case Study" url={i.link} />
				</div>
			);
		});
		return elements;
	}
	render(){
		var settings = {
			adaptiveHeight: true,
			arrows: true,
			dots: false,
			infinite: true,
			speed: 500,
			slide: ".slide",
			slidesToShow: 2,
			slidesToScroll: 1,
			prevArrow: <SliderButton className="slick-next" />,
			nextArrow: <SliderButton className="slick-prev" />,
			responsive: [
				{
					breakpoint: 768,
					settings: {
						arrows: false,
						dots: true,
						slidesToShow: 1
					}
				}
			]
		};
		return <div className="row csc-micro-case-studies">
			<div className="slider-container grid-m-6 grid-t-16 grid-d-16">
				<Slider {...settings}>
					{this.render_items(this.props.data)}
				</Slider>
			</div>
		</div>;
	}
}
