import React, { Component } from "react";

import { Link } from 'gatsby';
import Image from "./Image.js";

// import { TimelineMax, TweenMax } from 'gsap'
import 'intersection-observer';
import { InView } from 'react-intersection-observer'
// import { split } from '../utilities/split.js'
import { decode } from '../utilities/decode.js'

import { ArrowLink } from "../buttons/Arrow_Link.js";


export default class Overview extends Component {
	constructor(props){
		super(props);
		this.make_image = this.make_image.bind(this);
		this.make_services = this.make_services.bind(this);
		this.make_awards = this.make_awards.bind(this);
		this.make_site_link = this.make_site_link.bind(this);
		this.make_contact_link = this.make_contact_link.bind(this);
	}
	make_site_link(){
		var elements = [];
		if(this.props.site){
			elements.push(<ArrowLink title="Visit the site" url={this.props.site || "/"} />);
		}
		return elements;
	}
	make_contact_link(){
		var elements = [];
		if(this.props.contact === "true"){
			elements.push(<div className="cta-button-container default-animation invisible" ref={c=> this.workButton = c}><Link to="/contact/new-business" title="Go to new business page"><span className="button" ref={c=> this.workButton = c}>Work with us</span></Link></div>);
			if(this.props.link != undefined){
				elements.push(<div className="cta-button-container default-animation invisible" ref={c=> this.workButtonToo = c}><ArrowLink title="Learn more" url={"/work/"+this.props.link} /></div>);
			}
		}
		return elements;
	}
	make_image(){
		var elements = [];
		if(this.props.image){
			elements.push(<div key="overview-image" className="grid-m-6 grid-t-16 grid-d-16 overview-image"><Image url={this.props.image} alt={this.props.alt}  /></div>);
		}
		return elements;
	}
	make_services(){
		// var parent = this;
		if(this.props.services){
			var elements = [];
			this.props.services.forEach((i,x) => {
				elements.push(
					i.servicePage ?
						<li key={ "services-" + i.name }><a href={i.servicePage['url']} title="See service">{ decode(i.name) }</a></li>
					:
						<li key={ "services-" + i.name }>{ decode(i.name) }</li>
				);
			});
			return <React.Fragment>
				<h4 key="services-header" className="styling-eyebrow service-header default-animation invisible" ref={c=> this.serviceHeader = c}>Services</h4>
				<ul key="services-list" className="service-list default-animation invisible" ref={c=> this.serviceList = c}>{elements}</ul>
			</React.Fragment>
		};
	}
	make_awards(){
		// var parent = this;
		if(this.props.awards){
			var elements = [];
			this.props.awards.forEach((i,x) => {
				elements.push(<li key={ "award-" + i.name }>
					<p className="website">{ decode(i.award_website) }</p>
					<p className="award">{ decode(i.award_name) }</p>
				</li>);
			});
			return <React.Fragment>
				<h3 key="awards-header" className="styling-eyebrow award-header default-animation invisible" ref={c=> this.awardHeader = c}>Awards</h3>
				<ul key="awards-list" className="award-list default-animation invisible" ref={c=> this.awardList = c}>{elements}</ul>
			</React.Fragment>
		};
	}
	handleInView(inView) {
		if (inView) {
			this.title.classList.remove("invisible");
			this.desc.classList.remove("invisible");
			if(this.props.services){
				this.serviceHeader.classList.remove("invisible");
				this.serviceList.classList.remove("invisible");
			}
			if(this.props.awards){
				this.awardHeader.classList.remove("invisible");
				this.awardList.classList.remove("invisible");
			}
			if(this.props.contact){
				this.workButton.classList.remove("invisible");
				this.workButtonToo.classList.remove("invisible");
			}
		}
	}
	make_overview_text = () => {
		return <h2 style={{margin: 0}} dangerouslySetInnerHTML={{__html: this.props.description}}></h2>;
	}
	render() {
		return(
			<div className="overview grid">
				{this.make_image()}
				<InView as="div" triggerOnce={true} rootMargin="0% 0% -30%" onChange={inView => this.handleInView(inView)} className="row">
					<div className="grid-m-6 grid-t-9 grid-d-9">
						<span className="styling-eyebrow default-animation invisible" ref={c=> this.title = c}>Overview</span>
						<div className="styling-header-2 description-element default-animation invisible" ref={c=> this.desc = c}>
							{this.make_overview_text()}
						</div>
						{this.make_site_link()}
					</div>
					<div className="grid-m-6 push-t-1 grid-t-5 push-d-1 grid-d-5">
						{this.make_services()}
						{this.make_awards()}
						{this.make_contact_link()}
					</div>
				</InView>
			</div>
		);
	}
}
