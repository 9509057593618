import React, { Component } from "react";
import { findDOMNode } from "react-dom";
import utility_data from "../utilities/browser_information.js";

let node = null;


export default class Default_Input extends Component {
	constructor(props){
		super(props);
		this.state = {
			error_active: false,
			position: null,
			user_inputted: false
		};
		this.error_checker = this.error_checker.bind(this);
	}
	componentDidMount(){
		node = findDOMNode(this);
		const input = node.querySelector('input');
		input.addEventListener("blur", function(e){
			this.error_checker(e)
		}.bind(this))
		setTimeout(function(){
			this.setState({
				position: node.getBoundingClientRect().top + utility_data.scrollTop
			});
		}.bind(this), 200)
		window.addEventListener('scroll', this.scroll_handler);
	}
	componentWillUnmount(){
		window.removeEventListener('scroll', this.scroll_handler);
	}
	// checks input data on blur
	error_checker(e){
		node = findDOMNode(this);
		const input = node.querySelector('input');
		let no_errors_present = false;
		if(this.props.type === "email"){
			/* eslint-disable-next-line no-useless-escape */
			var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
			no_errors_present = re.test(String(input.value).toLowerCase());
		} else{
			if(input.value !== ""){
				no_errors_present = true;
			}
		}
		this.setState({
			user_inputted: true,
			error_active: no_errors_present
		});
	}
	render(){
		const error_active = !this.state.user_inputted || this.state.error_active ? "" : " error";
		const additionalClasses = this.props.additionalClasses ? " "+this.props.additionalClasses : "";
		return <div className={"default_input in-view " + additionalClasses + error_active + (this.props.type === 'hidden' ? 'hidden' : '')}>
			<input type={this.props.type} name={this.props.name} placeholder={this.props.placeholder} value={this.props.value} />
		</div>;
	}
}
